import React from 'react'

import BackgroundImage from '../images/rawpixel-594763-unsplash.jpg'
import Placeholder from '../images/placeholder.jpg'

import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import ImageText from '../components/imagetext'
import ConsultingAppointment from '../components/consultingappointment'
import LatestPosts from '../components/latestposts'
import FullImagetext from '../components/fullimagetext'
import ServiceBlog from '../components/serviceblog'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

const SecondPage = () => (
  <Layout>
    <div>
      <HeaderSubsite
        headline="About us"
        subline="Alles was Sie über uns wissen müssen"
        bg={BackgroundImage}
      />
      <ImageText
        bg={Placeholder}
        subline="Herzlich willkommen"
        headline="Schön dass Sie Interesse zeigen"
      >
        <p>
          <strong>Kanzlei</strong>
          <br />
          Vertrauen ist gut – Erfahrung und Know-how besser. Mit mehr als
          zwanzig Jahren Erfahrung beraten wir anspruchsvolle Privatmandanten,
          Freiberufler sowie mittelständische Unternehmen auf nationaler und
          internationaler Ebene in allen steuerlichen Belangen.
          <br />
          <br />
          Unser Beratungsansatz ist geprägt vom Grundsatz einer aktiven und
          vorausschauenden Steuerplanung und -gestaltung, in deren Mittelpunkt
          die Bedürfnisse und individuellen Anforderungen unserer Mandanten
          stehen.
          <br />
          <br />
          Wir hören genau zu, gehen auf Ihre Bedürfnisse ein und erarbeiten
          gemeinsam mit Ihnen tragfähige Strategien für die Gegenwart und die
          Zukunft, welche wir mit einem engagierten Team für Sie in die Tat
          umsetzen. Zuverlässig. Engagiert. Effizient. Dafür steht das gesamte
          Team der Steuerkanzlei Ruberto.
        </p>
      </ImageText>
      <ConsultingAppointment
        title="Sie wünschen ein Beratungstermin - nicht einfacher als das..."
        cta="Termin vereinbaren"
        bgcolor="#d3a04e"
      />
      <LatestPosts />
      <FullImagetext
        title="This is a Headline"
        image="https://via.placeholder.com/500"
      >
        Wir erstellen Ihre monatliche Finanz- und Lohnbuchhaltung,
        Jahresabschlüsse und Steuererklärungen. Zudem sind wir für Sie da, wenn
        es um Finanzierungs- und Investitionsfragen geht oder das
        Gestaltungspotential in Ihrem Unternehmen geprüft werden soll. Wir
        bieten elektronische Buchhaltungsschnittstellen an und unterstützen Sie
        aktiv bei der Digitalisierung aller steuerlich relevanten Prozesse in
        Ihrem Unternehmen.
      </FullImagetext>
      <FullImagetext
        direction="row-reverse"
        title="This is a Headline"
        image="https://via.placeholder.com/500"
      >
        Wir erstellen Ihre monatliche Finanz- und Lohnbuchhaltung,
        Jahresabschlüsse und Steuererklärungen. Zudem sind wir für Sie da, wenn
        es um Finanzierungs- und Investitionsfragen geht oder das
        Gestaltungspotential in Ihrem Unternehmen geprüft werden soll. Wir
        bieten elektronische Buchhaltungsschnittstellen an und unterstützen Sie
        aktiv bei der Digitalisierung aller steuerlich relevanten Prozesse in
        Ihrem Unternehmen.
      </FullImagetext>
      <ServiceBlog />
      <Footer />
      <SubFooter />
    </div>
  </Layout>
)

export default SecondPage
