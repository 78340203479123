import React from 'react'
import styled from 'styled-components'
import FixedImage from '../fixedimage'

const PostWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`

const Image = styled.img`
  margin: 0;
`

const PostContent = styled.div`
  margin: 0;
`

const PostTitel = styled.h2`
  color: #3f3f55;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 10px;
`

const PostTeaser = styled.p`
  color: #898989;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
`

const PostAuthor = styled.p`
  color: #898989;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
`

export default function Post(props) {
  const { image, title, children, author } = props // ", link"
  return (
    <PostWrapper>
      <FixedImage css="margin-bottom:20px" ratio={3 / 4}>
        <Image src={image} />
      </FixedImage>
      <PostContent>
        <PostTitel>{title}</PostTitel>
        <PostTeaser>{children}</PostTeaser>
        <PostAuthor>{author}</PostAuthor>
      </PostContent>
    </PostWrapper>
  )
}
