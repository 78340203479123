import React from 'react'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import Post from './post'
import HeadlineTwo from '../headlinetwo'
import HeadlineExtra from '../headlineextra'
import Underline from '../underline'
import Copytext from '../copytext'

const PostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default function LatestPosts() {
  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1280,
        padding: '1.45rem 1.0875rem 0',
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <HeadlineTwo>
        <HeadlineExtra>Unsere</HeadlineExtra> aktuellen News
      </HeadlineTwo>
      <Underline />
      <Copytext>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.
      </Copytext>
      <PostWrapper>
        <Post
          image="https://via.placeholder.com/350"
          title={<Trans>Projekt Title Test</Trans>}
          author={<Trans>Felix eigenbrodt</Trans>}
        >
          dfsghjghshdghdgfjfj
        </Post>
        <Post
          image="https://via.placeholder.com/350"
          title="this is a test"
          author="felix"
        >
          dfsghjghshdghdgfjfj
        </Post>
        <Post
          image="https://via.placeholder.com/350"
          title="this is a test"
          author="felix"
        >
          <Trans>fsghjghshdghdgfjfj</Trans>
        </Post>
      </PostWrapper>
    </div>
  )
}
